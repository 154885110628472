import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'
import { data } from 'vue-echarts'

export default function useConfirmationPlanningEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const { getItem, updateItem, ObjectId } = realmConnection()
  const confirmationPlanning = ref({})
  const isSubmitting = ref(false)
  const query = { _id: ObjectId(route.value.params.id) }
  const collection = 'confirmation_planning'

  const show = async () => {
    isSubmitting.value = true

    try {
      const item = await getItem({ collection, query })
      if (!item) throw new Error('Item not found')

        confirmationPlanning.value = {
          role: item.role?.toString(),
          process: item.process?.toString(),
          confirmationsPerYear: item.confirmationsPerYear
        }
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.confirmation_planning_fetch_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  const update = async data => {
    isSubmitting.value = true

    const payload = {
      role: ObjectId(data.role.value),
      process: ObjectId(data.process.value),
      confirmationsPerYear: Number(data.confirmationsPerYear.value),
    }

    try {
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.confirmation_planning_updated'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.confirmation_planning_update_error'))
    } finally {
      router.push({ name: 'organization-confirmation-planning-list' })
      isSubmitting.value = false
    }
  }

  return {
    update,
    show,
    confirmationPlanning,
    isSubmitting,
  }
}
